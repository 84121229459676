<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-input v-model="searchValus" placeholder="关键字" class="handle-input mr10"></el-input>
      </div>
      <div class="handle-box">
        <el-button type="primary" icon="search" @click="search">查询</el-button>
        <el-button type="primary" icon="delete" class="handle-del mr10" @click="handleAdd">新增</el-button>
        <el-button type="primary" icon="delete" class="handle-del mr10" @click="handleEdit">编辑</el-button>
        <el-button type="danger" class="handle-del mr10" @click="handleDel()">删除</el-button>
      </div>
      <el-table class="z_default_table" :empty-text="emptyText" :row-style="rowStyle" v-loading="tableLoading"
        element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.5)" :data="tableData" border
        style="width: 100%" ref="multipleTable" use-virtual         height="600"
        @selection-change="handleSelectionChange" @select="handleCheckBox" @select-all="handleSelectAll"
        @row-click="handleRowClick">
        <el-table-column type="selection" align="center" width="40"></el-table-column>
        <el-table-column align="center" prop="id" label="驿站编号"></el-table-column>
        <el-table-column align="center" prop="stationName" label="驿站名称"></el-table-column>
        <el-table-column align="center" prop="doorLockCode" label="驿站门锁编号"></el-table-column>
        <el-table-column align="center" prop="doorLockTime" label="开门时间"></el-table-column>
        <el-table-column align="center" label="小程序二维码">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-s-grid" circle @click="qrcodeImage(scope.row)"></el-button>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="appletServiceUrl" label="客服连接"></el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间"
          :formatter="createTimeFormatter"></el-table-column>
        <el-table-column align="center" prop="loginName" label="合一账号"></el-table-column>
        <el-table-column align="center" prop="password" label="合一密码"></el-table-column>
        <el-table-column align="center" prop="jhStationId" label="合一驿站ID"></el-table-column>
        <el-table-column align="center" prop="lightSiteStatus" label="是否有灯条"
          :formatter="lightSiteStatusFormatter"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
          :page-sizes="pageSizes" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"></el-pagination>
      </div>
    </div>

    <!-- 新增弹出框 -->
    <el-dialog title="新增驿站" :visible.sync="addVisible" width="40%" class="dialog-border-radius">
      <el-form ref="addForm" :model="addForm" label-width="120px" :label-position="labelPosition" class="dialog-form">
        <!--<el-form-item label="驿站编号">
            <el-input v-model="addForm.id"></el-input>
          </el-form-item>-->
        <el-form-item label="驿站名称">
          <el-input v-model="addForm.stationName"></el-input>
        </el-form-item>
        <el-form-item label="驿站门锁编号">
          <el-input v-model="addForm.doorLockCode"></el-input>
        </el-form-item>
        <el-form-item label="开门时间">
          <el-input v-model="addForm.doorLockTime"></el-input>
        </el-form-item>
        <el-form-item label="客服连接">
          <el-input v-model="addForm.appletServiceUrl"></el-input>
        </el-form-item>
        <el-form-item label="是否有灯条">
          <el-select v-model="addForm.lightSiteStatus" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in lightSiteStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合一信息">
          <el-input placeholder="合一账号" v-model="addForm.loginName" style="margin-bottom: 12px;"></el-input>
          <el-input placeholder="合一密码" v-model="addForm.password" style="margin-bottom: 12px;"></el-input>
          <el-input placeholder="合一驿站ID" v-model="addForm.jhStationId"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑驿站" :visible.sync="editVisible" width="40%" class="dialog-border-radius">
      <el-form ref="form" :model="form" label-width="120px" :label-position="labelPosition" class="dialog-form">
        <!--<el-form-item label="驿站编号">
            <el-input v-model="form.stationCode"></el-input>
          </el-form-item>-->
        <el-form-item label="驿站名称">
          <el-input v-model="form.stationName"></el-input>
        </el-form-item>
        <el-form-item label="驿站门锁编号">
          <el-input v-model="form.doorLockCode"></el-input>
        </el-form-item>
        <el-form-item label="开门时间">
          <el-input v-model="form.doorLockTime"></el-input>
        </el-form-item>
        <el-form-item label="客服连接">
          <el-input v-model="form.appletServiceUrl"></el-input>
        </el-form-item>
        <el-form-item label="是否有灯条">
          <el-select v-model="form.lightSiteStatus" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in lightSiteStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合一信息">
          <el-input placeholder="合一账号" v-model="form.loginName" style="margin-bottom: 12px;"></el-input>
          <el-input placeholder="合一密码" v-model="form.password" style="margin-bottom: 12px;"></el-input>
          <el-input placeholder="合一驿站ID" v-model="form.jhStationId"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除选中数据不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteRow">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="二维码" :visible.sync="qrcodeVisible" width="300px" center>
      <div class="flex-column-center-container" style="position: relative;overflow: hidden;">
        <div id="originImg" style="position: absolute;z-index: -1;top: -9999px">
          <!-- 二维码对象可以通过 ref 绑定 -->
          <div style="margin: 20px 20px 0;" id="qrCode" ref="qrCodeDiv"></div>
          <div class="qr-tips" style="font-size: 16px; color: #5873d8;padding: 10px 0;text-align: center;"
            v-if="curRow && curRow.stationName">{{ curRow.stationName || '' }}</div>
        </div>
        <div id="showImg" :title="`https://yc.dingxin-best.com/lock?stationId=${curRow.id}&type=1`"></div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import html2canvas from 'html2canvas';
import global from "@/common/global";
import { decodeUserInfo, requestParams, currentDate } from "@/common/tools";
export default {
  components: {},
  data() {
    return {
      userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
      pageSize: global.pageSize,
      pageSizes: global.pageSizes,
      pageIndex: 1,
      beforeRow: null,
      tableData: [], //表格数据
      tableTotal: 0,
      searchValus: "",
      multipleSelection: [], //选中的行
      addVisible: false,
      qrcodeVisible: false,
      editVisible: false,
      addForm: {
      },
      resetVisible: false,
      delVisible: false,
      form: {
      },
      labelPosition: "right",
      indexArr: [],
      tableLoading: false,
      curRow: {},
      lightSiteStatusList: [{ label: "否", value: 0 }, { label: "是", value: 1 }]
    };
  },
  created() {
    this.getData();
  },
  updated() {

  },
  computed: {

  },
  methods: {
    rowStyle({ row, rowIndex }) {
      for (var i in this.indexArr) {
        if (rowIndex == this.indexArr[i].index) {
          return "background:" + global.listColor + ";";
        }
      }
    },
    handleSelectAll(selection) {
      this.$refs.multipleTable.clearSelection();
    },
    handleAdd() {
      this.addVisible = true;
    },
    handleReset(index, row) {
      console.log(this.multipleSelection);
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length == 1) {
        var item = multipleSelection[0];
        this.form.userId = item.userId;
        this.resetVisible = true;
      } else if (multipleSelection.length > 1) {
        this.$message({
          showClose: true,
          message: "只能选择一条记录",
          type: "error"
        });
      } else {
        this.$message({
          showClose: true,
          message: "请选择需要操作的记录",
          type: "error"
        });
      }
    }, saveAdd() {
      if (!this.addForm.stationName || this.addForm.stationName == "") {
        this.$message.error("驿站名称不能为空");
      } else {
        var data = this.addForm;
        this.$axios
          .post("/oa/station/saveStation", this.$qs.stringify(data))
          .then(res => {
            if (res.data.code == 0) {
              this.addVisible = false;
              //console.log(this.addForm);
              this.addForm = {};
              this.$message.success(`新增成功`);
              this.getData();
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    }, saveEdit() {
      if (!this.form.stationName || this.form.stationName == "") {
        this.$message.error("驿站名称不能为空");
      } else {
        var data = this.form;
        this.$axios
          .post("/oa/station/updateStation", this.$qs.stringify(data))
          .then(res => {
            if (res.data.code == 0) {
              this.editVisible = false;
              //console.log(this.addForm);
              this.$message.success(`修改成功`);
              this.getData();
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    qrcodeImage(row) {
      this.qrcodeVisible = true;
      this.curRow = row;
      var urltext = "https://yc.dingxin-best.com/lock?stationId=" + row.id + "&type=1";
      this.createQRCode(urltext);
    },
    createQRCode(url) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        new QRCode(this.$refs.qrCodeDiv, {
          text: url,//二维码链接，参数是否添加看需求
          width: 200,//二维码宽度
          height: 200,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
        });

        let originImg = document.getElementById('originImg');
        let showImg = document.getElementById('showImg');
        html2canvas(originImg).then((canvas) => {
          console.log(canvas, this.curRow);
          showImg.innerHTML = "";
          showImg.style = "text-align: right;"
          showImg.appendChild(canvas);

          let url = canvas.toDataURL('images/png');
          let a = document.createElement('a');
          a.style = "text-decoration: underline;color: #5873d8;font-size: 20px;";
          a.innerHTML = '<i class="el-icon-download"></i>';
          a.download = `${this.curRow.stationName || '二维码'}.png`;
          a.href = url;
          showImg.appendChild(a);
        })
      })

    }, handleEdit(index, row) {
      console.log(this.multipleSelection);
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length == 1) {
        var item = multipleSelection[0];
        this.form = JSON.parse(JSON.stringify(item));
        this.editVisible = true;
      } else if (multipleSelection.length > 1) {
        this.$message({
          showClose: true,
          message: "只能选择一条记录",
          type: "error"
        });
      } else {
        this.$message({
          showClose: true,
          message: "请选择需要操作的记录",
          type: "error"
        });
      }
    }, handleDel() {
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length >= 1) {
        this.delVisible = true;
      } else {
        this.$message({
          showClose: true,
          message: "请选择需要操作的记录",
          type: "error"
        });
      }

    }, deleteRow() {
      var indexArr = this.indexArr;
      var idStr = '';
      var data = {};
      if (this.indexArr.length >= 1) {
        for (var i in indexArr) {
          idStr += indexArr[i].id + ',';
        }
        idStr = idStr.substring(0, idStr.length - 1);
        data.ids = idStr;
      }
      data = requestParams(data);
      this.$axios
        .post(
          "/oa/station/deleteStationId",
          this.$qs.stringify(data)
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("删除");
            this.delVisible = false;
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        });

    },

    // 分页导航
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    checkStatusTypeChange() {
      this.pageIndex = 1;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      var data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        searchValus: this.searchValus
      };
      this.emptyText = " ";
      this.$axios
        .post("/oa/station/list", this.$qs.stringify(data))
        .then(res => {
          console.log(res);
          this.tableData = res.data.data.rows;
          this.tableLoading = false;
          this.tableTotal = Number(res.data.data.total);
          this.emptyText = "暂无数据";
        })
        .catch(error => {
          this.emptyText = "暂无数据";
          this.tableLoading = false;
        });
    },
    search() {
      //   alert(this.select_cate);
      this.getData();
    },
    handleSelectionChange(val) {
      if (val.length > 1) return;
      this.multipleSelection = val;
      this.indexArr = [];
      if (this.multipleSelection.length >= 1) {
        for (var i in this.tableData) {
          for (var j in this.multipleSelection) {
            if (this.tableData[i].id == this.multipleSelection[j].id) {
              this.indexArr[j] = {
                index: i,
                id: this.multipleSelection[j].id
              };
            }
          }
        }
      }
    },
    handleCheckBox(selection, row) {
      // console.log(this.beforeRow, row, this.indexArr, this.multipleSelection)
      if (!this.beforeRow) {
        this.beforeRow = row;
      } else {
        if (this.beforeRow != row) {
          this.beforeRow = row;
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(row);
        } else {
          if (this.indexArr.length > 0) {
            this.$refs.multipleTable.clearSelection();
          } else {
            this.$refs.multipleTable.clearSelection();
            this.$refs.multipleTable.toggleRowSelection(row);
          }
        }
      }
    },
    handleRowClick(row, event, column) {
      if (!this.beforeRow) {
        this.beforeRow = row;
      } else {
        if (this.beforeRow != row) {
          this.beforeRow = row;
          this.$refs.multipleTable.clearSelection();
        }
      }
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    createTimeFormatter(row, column, cellValue, index) {
      return currentDate({
        timestamp: cellValue
      });
    },
    lightSiteStatusFormatter(row, column, cellValue, index) {
      console.log(row, column, cellValue, index)
      let filterList = this.lightSiteStatusList.filter(item => `${item.value}` == `${cellValue}`);
      return filterList.length > 0 ? filterList[0].label : '';
    }

  }
};
</script>

<style scoped>
.table {
  height: 100%;
}

.table,
.container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.table .el-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.search_select {
  width: 160px !important;
}

.handle-input {
  width: 160px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.select_name {
  display: inline-block;
  font-size: 14px;
}

.select_container {
  display: inline-block;
}

.select_container .el-date-editor.el-input {
  width: 140px;
}

.brandMs p {
  text-align: left;
}
</style>